import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import SectionComponent from 'src/components/SectionComponent'
import { H2Citrina } from 'src/components/Text/Headings'
import { InterP } from 'src/components/Text/BodyText'

import { ButtonWithDeepLink } from 'src/pages/recarga/components/_ButtonWithDeepLink'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import { ImageWebpWithSizes } from 'src/components/ImageWebp'
import { PixCreditoProps } from '../_types'

const TEXT_CONTENT = {
  heading: 'Pix Crédito: aqui tem!',
  paragraph: 'Mais liberdade para pagar ou transferir. Com o Pix Crédito, você usa o limite disponível no seu cartão para realizar transferências instantâneas e parcelar em até 18x. O valor cai na hora e você só paga depois, na fatura.',
  cta: 'Fazer Pix com cartão',
}

const dataLayerEvent: IDataLayerParams = {
  section: 'dobra_06',
  element_action: 'click button',
  element_name: TEXT_CONTENT.cta,
  section_name: TEXT_CONTENT.heading,
}

const PixCredito = ({ onCtaClickHandler, oneLink }: PixCreditoProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const handleClick = () => {
    onCtaClickHandler(PixCredito.name)
    sendDatalayerEvent(dataLayerEvent)
  }

  return (
    <SectionComponent
      id='pix-credito'
      SectionStyles='p-0'
      minHeight={{ sm: '587px', md: '358px', lg: '411px', xl: '500px', xxl: '500px' }}
    >
      <div className='col-12 col-md-6 order-last order-md-1'>
        <ImageWebpWithSizes
          pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/pix-dobra5-web/image.webp'
          altDescription='tela de celular mostrando a área Pix no Super App Inter'
        />
      </div>
      <div className='col-12 col-md-5 col-xl-5 mb-5 order-md-2 pt-5'>
        <H2Citrina
          fontWeight={{ sm: '700', md: '700', lg: '700', xl: '700' }}
          className='mb-3'
          fontSize={{ xl: '40px' }}
          lineHeight={{ xl: '44px' }}
        >{TEXT_CONTENT.heading}
        </H2Citrina>
        <InterP className='mb-3'>{TEXT_CONTENT.paragraph}</InterP>
        <div className='mt-3'>
          <ButtonWithDeepLink
            className='mt-3 btn btn-orange--extra btn--lg rounded-2 fs-14 fw-700 text-none text-md-center'
            href={oneLink}
            onClick={handleClick}
            onLinkClick={() => sendDatalayerEvent(dataLayerEvent)}
          >
            {TEXT_CONTENT.cta}
          </ButtonWithDeepLink>
        </div>
      </div>
    </SectionComponent>
  )
}

export default PixCredito
