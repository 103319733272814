import { breakpoints, device } from 'src/styles/breakpoints'
import { grayscale, white } from 'src/styles/colors'
import styled, { css, keyframes } from 'styled-components'

const animatedModal = keyframes`
  from {
    right: -100%;
  }
  to {
    right: 0;
  }
`
type ContainerProps = {
  isModal?: () => void;
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  background-color: ${white};
  font-family: "Sora", Helvetica, sans-serif;
  height: 100vh;

  input {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #6A6C72;
  }

  img {
    max-width: 204px;
  }

  ${(props: ContainerProps) => props.isModal && (
    css`
      padding: 50px 30px 40px;
      transition: 0.5s ease-in-out;
      animation: ${animatedModal} 0.5s ease-in-out forwards;

      @media (min-width: ${breakpoints.md}) {
        margin-top: 15vh;
        max-width: 640px;
        height: 652px;
        padding: 40px 20px;
      }
      @media (min-width: ${breakpoints.lg}) {
        margin-top: 10vh;
        height: 80vh;
        margin-bottom: 10vh;
      }
      @media (min-width: ${breakpoints.xl}) {
        margin-top: 5vh;
        height: 90vh;
        margin-bottom: 5vh;
      }
      @media (min-width: ${breakpoints.xxxl}) {
        margin-top: 13vh;
        height: 652px;
      }
    `
  )}
  
  .form--default {   
    label {
      color: ${grayscale['400']};
    }
  }

  .btn {
    height: 40px;
    @media ${device.tablet} {
      min-width: 100%;
      height: 48px;
    }
  }
`

export const CloseButton = styled.button`
  position: absolute;
  right: 25px;
  top: 0;
  z-index: 10;
  outline: none !important;
`

export const QRCodeDiv = styled.div`
  padding: 36px 0;
  div {
    max-width: 200px;
    max-height: 200px;
  }
`
