import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const Wrapper = styled.div`

  h1, h2, h3, h4, h5 {
    font-family: "Sora", Helvetica, sans-serif;
  }

  .gatsby-image-wrapper {
    @media ${device.desktopXL} {
      width: 456px;
      float: right;
    }
  }
`
