import { breakpoints } from 'src/styles/breakpoints'
import { grayscale, orange, white } from 'src/styles/colors'
import { gray } from 'src/styles/newColors'
import styled from 'styled-components'

export const Step = styled.div`
  font-family: Inter, 'sans-serif';
  position: relative;
  color: ${grayscale[400]};
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  @media (min-width: ${breakpoints.md}) {
    font-size: 13px;
    line-height: 16px;
  }
  @media (min-width: ${breakpoints.lg}) {
    font-size: 14px;
    line-height: 17px;
  }
  @media (min-width: ${breakpoints.xl}) {
    font-size: 18px;
    line-height: 21px;
  }
`

export const Circle = styled.div`
  background: ${orange.extra};
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  color: ${white};
  text-align: center;
  font-size: 10px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  @media (min-width: ${breakpoints.xl}) {
    width: 24px;
    height: 24px;
    font-size: 13px;
  }
`

export const StepContainer = styled.div`

  ${Step} + ${Step} {
    margin-top: 20px;
  }

  .content {
    font-family: "Sora", Helvetica, sans-serif;
    display: flex;
    align-items: center;
  }

  ${Circle}:after {
    content: '';
    position: absolute;
    display: block;
    right: 50%;
    bottom: 1px;
    left: 48%;
    height: 22px;
    width: 1px;
    transform: scale(1, 2);
    transform-origin: 50% -100%;
    background-color: ${gray};
    z-index: 0;
  }

  ${Step}:last-child ${Circle}:after {
    display: none;
  }
`
